<template>
  <div>
    <v-app-bar elevate-on-scroll class="secondary d-flex flex-column">
      <v-app-bar-nav-icon class="" @click="hideNavigation" />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
 
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "app-bar",
  props: ["title"],
  methods: {
    hideNavigation() {
      this.$store.dispatch(
        "hideNavigation",
        !this.$store.state.navigationBar.isVisible
      );
    },
  },
};
</script>
