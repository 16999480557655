import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store/store";
import axios from 'axios';
import Notifications from 'vue-notification'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

//import { ValidationProvider } from 'vee-validate';

import { onSuccess, onError, beforeRequestSuccess, beforeRequestError } from './interceptors/jwt';

axios.interceptors.request.use(beforeRequestSuccess, beforeRequestError);
axios.interceptors.response.use(onSuccess, onError);

Vue.config.productionTip = false;

Vue.use(Notifications)
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.isLoggedIn) {
    next({ name: "Login", params: { targetPath: to.path } });
  } else next();
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
