import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Navigation from "../views/Navigation.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Home",
    path: "/",
    components: { default: Home, navigation: Navigation },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    props: true,
  },
  {
    name: "administration",
    path: "/administration",
    components: {
      default: () => 
        import("../views/Administration.vue"),
        navigation: Navigation
    }    
  },
  {
    name: "users",
    path: "/users",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "users" */
          "../views/Users.vue"
        ),
      navigation: Navigation,
    },
  },
  {
    name: "new_user",
    path: "/users/new",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "users" */
          "../views/UserDetails.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
    },
  },
  {
    name: "edit_user",
    path: "/users/:userId",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "users" */
          "../views/UserDetails.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
    },
  },
  {
    name: "import",
    path: "/import",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "import" */
          "../views/Import.vue"
        ),
      navigation: Navigation,
    },
  },
  {
    name: "vehicle",
    path: "/vehicle/:vehicleId",
    // component: () =>
    //     import(
    //       /* webpackChunkName: "vehicle-editor" */
    //       /* webpackMode: "lazy" */
    //       "../views/VehicleEditor.vue"
    //     ),
    components: {
      default: () =>
        import(
          /* webpackChunkName: "vehicle-editor" */
          /* webpackMode: "lazy" */
          "../views/VehicleEditor.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
    },
  },
  {
    name: "new_vehicle",
    path: "/vehicle/new",
    // component: () =>
    //     import(
    //       /* webpackChunkName: "vehicle-editor" */
    //       /* webpackMode: "lazy" */
    //       "../views/VehicleEditor.vue"
    //     ),
    components: {
      default: () =>
        import(
          /* webpackChunkName: "vehicle-editor" */
          /* webpackMode: "lazy" */
          "../views/VehicleEditor.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
   
    },
  },
  {
    name: "new_vehicle_with_configuration",
    path: "/vehicle/new_configuration",
    // component: () =>
    //     import(
    //       /* webpackChunkName: "vehicle-editor" */
    //       /* webpackMode: "lazy" */
    //       "../views/VehicleEditor.vue"
    //     ),
    components: {
      default: () =>
        import(
          /* webpackChunkName: "vehicle-editor" */
          /* webpackMode: "lazy" */
          "../views/Configurator.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
   
    },
  },
  {
    name: "new_vehicle_with_car_editor",
    path: "/vehicle/car_editor",
    // component: () =>
    //     import(
    //       /* webpackChunkName: "vehicle-editor" */
    //       /* webpackMode: "lazy" */
    //       "../views/VehicleEditor.vue"
    //     ),
    components: {
      default: () =>
        import(
          /* webpackChunkName: "vehicle-editor" */
          /* webpackMode: "lazy" */
          "../views/CarEditor.vue"
        ),
      navigation: Navigation,
    },
    props: {
      default: true,
   
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
