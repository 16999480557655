import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align-content":"end"}},[_c(VCol,[_c(VBtn,{on:{"click":function($event){return _vm.createVehicle()}}},[_c(VIcon,{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-note-plus-outline")]),_vm._v(" Neu ")],1),_vm._v("   "),_c(VBtn,{on:{"click":function($event){return _vm.createVehicleFromConfigurator()}}},[_c(VIcon,{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-note-plus-outline")]),_vm._v(" Neu aus Konfigurator ")],1),_vm._v("   "),_c(VBtn,{on:{"click":function ($event) { return _vm.createVehiclWithCarEditor(); }}},[_c(VIcon,{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-cog-play-outline")]),_vm._v(" Fahrzeug zusammenstellen ")],1)],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.dataTableHeaders,"items":_vm.vehicles,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStateLabel(item.state))+" ")]}},{key:"item.uploadedImages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uploadedImages ? item.uploadedImages.length : 0)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEditable(item.state))?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editVehicle(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isEditable(item.state))?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.requestDeletion(item)}}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }