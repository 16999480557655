import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URI;

export default {
    create(carData) {
        return axios.put(apiUrl + '/CarData', carData).then(res => res.data).catch(err => Promise.reject(err));
    },
    getVehicle(vehicleId) {
        return axios.get(apiUrl + '/CarData/Get/' + vehicleId).then(res => res.data).catch(err => Promise.reject(err));
    },
    getAll() {
        return axios.get(apiUrl + '/CarData').then(res => res.data).catch(err => Promise.reject(err));
    },
    update(vehicleData) {
        return axios.post(apiUrl + '/CarData', vehicleData).then(res => res.data).catch(err => Promise.reject(err));
    },
    publish(vehicleId) {
        return axios.post(apiUrl + '/CarData/Publish', { Id: vehicleId }).then(res => res.data).catch(err => Promise.reject(err));
    },
    delete(vehicleId) {
        return axios.post(apiUrl + '/CarData/Delete', { Id: vehicleId }).then(res => res.data).catch(err => Promise.reject(err));
    },
    import(file, autoPublish) {
        var formData = new FormData();
        formData.append("UploadedFile", file);
        formData.append("AutoPublish", autoPublish);
        return axios.post(apiUrl + '/CarData/Import', formData, {
            'Content-Type': 'multipart/form-data'
        }).then(res => res.data).catch(err => Promise.reject(err));
    },
    uploadImages(vehicleId, files) {
        var formData = new FormData();
        formData.append("Id", vehicleId);

        for (const i of Object.keys(files)) {
            formData.append("Files", files[i]);
        }

        return axios.post(apiUrl + '/CarData/ImageUpload', formData, {
            'Content-Type': 'multipart/form-data'
        }).then(res => res.data).catch(err => Promise.reject(err));
    },
    addImage(vehicleId, key) {
        var formData = new FormData();
        formData.append("Id", vehicleId);
        formData.append("Key", key);

        return axios.post(apiUrl+'/CarData/AddImage', formData, {
            'Content-Type': 'multipart/form-data'
        }).then(res => res.data).catch(err => Promise.reject(err));
    },
    deleteImage(vehicleId, imageToDelete) {
        return axios.post(apiUrl+'/CarData/DeleteImage', {
            Id: vehicleId,
            Image: imageToDelete 
        }).then(res => res.data).catch(err => Promise.reject(err));
    },
    republishPublishedCars() {
        return axios.post(apiUrl+'/CarData/Republish').then(res => res.data).catch(err => Promise.reject(err));
    }
}