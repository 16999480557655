import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";


const state = {
    accessToken: "",
    userInfo: {}
};

const getters = {
    accessToken() {
        return state.accessToken;
    },
    userInfo() {
        return state.userInfo;
    },
    authenticated() {
        return state.accessToken != null;
    }
};

const actions = {
    authenticate({ commit }, loginModel) {
        return AuthService.login(loginModel)
            .then(res => {
                if (res.success && res.token) {
                    commit("accessToken", res.token);

                    UserService.me().then(res => {
                        commit("userInfo", res);
                        return Promise.resolve();                        
                    }).catch(err => {
                        Promise.reject(err);
                    });

                    return Promise.resolve();
                }
                return Promise.reject();                
            }).catch(err => {
                return Promise.reject(err);
            })
    }
}

const mutations = {
    accessToken(state, token) {
        state.accessToken = token;
    },
    userInfo(state, userInfo) {
        state.userInfo = userInfo;
    },
    tenant(state, tenant) {
        state.tenant = tenant;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}