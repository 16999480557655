import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
//import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    // Temporary loggedin state for demonstration purposes
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
    navigationBar: {
      isVisible: true,
    },
  },
  mutations: {
    HIDE_NAVIGATION(state, value) {
      state.navigationBar.isVisible = value;
    },
    LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
  },
  actions: {
    hideNavigation(context, value) {
      context.commit("HIDE_NAVIGATION", value);
    },
    setLoggedIn(context, value) {
      context.commit("LOGGED_IN", value);
    },
  },
  modules: {
    auth,

  },
});
