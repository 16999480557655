<template>
  <div>
    <v-container fluid>
      <v-row align-content="end">
        <v-col>
          <v-btn @click="createVehicle()">
            <v-icon left color="grey">mdi-note-plus-outline</v-icon>
            Neu
          </v-btn>
          &nbsp;
          <v-btn @click="createVehicleFromConfigurator()">
            <v-icon left color="grey">mdi-note-plus-outline</v-icon>
            Neu aus Konfigurator
          </v-btn>
          &nbsp;        
          <v-btn @click="$event => createVehiclWithCarEditor()">
            <v-icon left color="grey">mdi-cog-play-outline</v-icon>
            Fahrzeug zusammenstellen
          </v-btn>
        </v-col>              
      </v-row>
    </v-container>
    <v-data-table
      :headers="dataTableHeaders"
      :items="vehicles"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-slot:item.state="{ item }">
        {{ getStateLabel(item.state) }}
      </template>
      
      <template v-slot:item.uploadedImages="{item}">
        {{item.uploadedImages ? item.uploadedImages.length : 0 }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editVehicle(item.id)"
          v-if="isEditable(item.state)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="requestDeletion(item)"
          v-if="isEditable(item.state)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CarDataService from "../services/CarDataService";

export default {
  name: "vehicle-list",
  components: {},
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loadVehicles();
      },
      { immediate: true }
    );
  },
  methods: {
    createVehicle() {
      this.$router.push({ name: "new_vehicle", params: { isNew: true } });
    },
    createVehicleFromConfigurator() {
      this.$router.push({ name: "new_vehicle_with_configuration"});
    },
    createVehiclWithCarEditor() {
      this.$router.push({name: "new_vehicle_with_car_editor"});
    },
    loadVehicles() {
      var vm = this;
      CarDataService.getAll().then((vehicles) => {
        vm.vehicles = vehicles;
      });
    },
    editVehicle(vehicleId) {
      this.$router.push({
        name: "vehicle",
        params: { vehicleId: vehicleId, isNew: false },
      });
    },
    isEditable(state) {
      return state !== 1 && state !== 3 && state !== 4;
    },
    requestDeletion(item) {
      let vm = this;

      this.$dialog
        .confirm({
          text: "Möchten Sie den Datensatz wirklich löschen?",
          title: "Datensatz löschen",
          actions: {
            false: "Nein",
            true: "Ja",
          },
        })
        .then((res) => {
          if (res) {
            vm.markAsDeleted(item);
          }
        });
    },
    markAsDeleted(item) {
      let vm = this;

      if (item) {
        CarDataService.delete(item.id)
          .then(() => {
            vm.$notify({
              title: "Datensatz löschen",
              text: "Der Datensatz wurde zum Löschen vorgemerkt.",
              type: "success",
            });
            vm.loadVehicles();
          })
          .catch(() => {
            vm.$notify({
              title: "Fehler beim Löschen des Datensatzes.",
              text: "Der Datensatz konnte nicht zum Löschen vorgemerkt werden.",
              type: "error",
            });
          });
      }
    },
    getStateLabel(state) {
      switch (state) {
        case 0:
          return "Bearbeitbar";
        case 1:
          return "Wird veröffentlicht";
        case 2:
          return "Veröffentlicht";
        case 3:
          return "Wird gelöscht";
        case 4:
          return "Gelöscht";
      }
    },
  },
  data() {
    return {
      dataTableHeaders: [
        {
          text: "id",
          value: "id",
          sortable: true,
        },
        {
          text: "Interne Nummer",
          value: "values.id_1",
          sortable: true,
        },
        {
          text: "Status",
          value: "state",
          sortable: true,
        },
        {
          text: "Marke",
          value: "values.id_3",
          sortable: true,
        },
        {
          text: "Modell",
          value: "values.id_4",
          sortable: true,
        },
        {
          text: "Bilder",
          value: "uploadedImages",
          sortable: false
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
        },
      ],
      vehicles: [],
    };
  },
};
</script>
